import React from 'react'
import PropTypes from 'prop-types'
// import { useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import '../../styles/mainPostCard.scss'
//import { formatImageUrl } from '../../utils/utils'

const MainPostCard = ({ post }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    const elipsis = (str) => {
        var larg = (typeof document !== `undefined` ? document.body.clientWidth : 1151)

        const length = (larg <= 1112 && larg > 1150 ? 300 :
            larg <= 1150 && larg > 1029 ? 250 :
                larg <= 1029 && larg > 1007 ? 170 : 380)

        const myRegex = /\[.+\]/g // regex pour supprimer ce qu'il y a entre crochets (les liens)
        str = str.replace(myRegex ,``)

        return str.substring(0, length) + `...`
    }

    return (
        <Link to={url} className="post-card">
            {post.feature_image &&
                    <img className="post-card-image" src={post.feature_image} loading="lazy"></img>}
            <div className="post-card-text">
                <header className="post-card-header">

                    <div className="post-card-tags">
                        {post.tags && post.tags.map(tag => (tag.name.charAt(0) !== `#` ? <span key={tag.name}>{tag.name}</span> : ``)
                        )}
                    </div>
                    {post.featured && <span>Featured</span>}
                    <h2 className="post-card-title"><span className="underline">{post.title}</span></h2>
                </header>
                <section className="post-card-excerpt">
                    <span>{elipsis(post.excerpt)}</span>
                    <span>&nbsp;Lire la suite</span>
                </section>
                <hr className="post-card-break" />
                <footer className="post-card-footer">
                    <div className="post-card-footer-left">
                        <span>{post.published_at_pretty}&nbsp;&nbsp;</span>
                        {/*Pas possible de traduire le readingTime qui provient du SDK de Ghost, du coup je coupe le read */}
                        <span>&#9679;&nbsp;&nbsp;{readingTime.substring(0, readingTime.indexOf(`r`))}</span>                    </div>
                </footer>
            </div>

        </Link>
    )
}

MainPostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        date: PropTypes.string,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default MainPostCard
