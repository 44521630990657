import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard } from '../components/common'
import MainPostCard from '../components/common/MainPostCard'
import { MetaData } from '../components/common/meta'
import BookImage from '../images/book.png'
import SocialShare from '../components/common/SocialShare'
import { useScroll, useWindowSize } from '../hooks'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location }) => {
    const posts = data.allGhostPost.edges
    const firstPost = data.allGhostPost.edges[0].node
    const scroll = useScroll()
    const size = useWindowSize()
    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                <div className="container">
                    {size.width >= 1240 && <SocialShare round={false} className={`social-share ${size.width >= 1240 ? `big` : `hide`} ${scroll ? `scroll-share` : ``}`}/>}
                    <section className="main-post">
                        <MainPostCard key={firstPost.id} post={firstPost}/>
                    </section>
                    <section className="post-feed">
                        {posts.slice(1).map(({ node }, i) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <React.Fragment key={i}>
                                {i === 2 && <a target="_blank" rel="noreferrer"href="https://flashs-bruxellois.starke.fr/"><img className="special" height="429" src={BookImage} /></a>}
                                <PostCard post={node} />
                            </React.Fragment>

                        ))}
                    </section>
                </div>
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
